.apod{
	background-color: var(--plain-white);
	box-shadow: 0px 3px 6px #00000029;
	overflow: hidden;
	height: 600px;
	margin-bottom: 60px;
	display: flex;
	position: relative;
	transition: all 0.2s ease-in-out;
}
.apod .image-wrapper{
	margin: 0px;
	padding: 0px;
	overflow: hidden;
	width: 50%;
	order: 1;
}
.apod .image-wrapper img{
	object-fit: cover;
	width: 120%;
	height: 100%;
	margin-left: -10%;
}
.apod .image-wrapper figcaption{
	text-indent: -9999px;
}
.apod .apod-info{
	width: 50%;
	padding-top: 50px;
	padding-left: 50px;
	padding-right: 50px;
	box-sizing: border-box;
	order: 2;
}
.apod .apod-info h2{
	margin-top: 0px;
	margin-bottom: 15px;
	color: var(--header-color);
	font-weight: 600;
}
.apod .apod-info h2 a{
	display: inline-block;
	width: 100%;
	color: var(--main-nav);
	text-decoration: none;
	transition: all 0.3s ease-in-out;
}
.apod .apod-info h2 a:hover, 
.apod .apod-info h2 a:focus{
	text-decoration: underline;
}
.apod .apod-info > p{
	margin-top: 0px;
	margin-bottom: 15px;
	font-size: 14px;
	line-height: 25px;
	color: var(--text-color);
}
.apod .apod-info .meta{
	display: flex;
	color: var(--text-color);
}
.apod .apod-info .meta p{
	margin-right: 15px;
	margin-top: 0px;
	margin-bottom: 15px;
	min-width: 160px;
	font-style: italic;
}
.apod .apod-info .meta p:last-child{
	margin-right: 0px;
}
.apod .apod-info .meta label{
	color: var(--main-nav);
	font-weight: 600;
	font-style: normal;
	margin-right: 5px;
}
.apod:last-child{
	margin-bottom: 0px;
}
.apod .actions{
	position: absolute;
	bottom: 0;
	right: 0;
}
.apod .actions a{
	display: inline-block;
	padding: 15px 25px;
	font-weight: 600;
	font-size: 16;
	border: 2px solid transparent;
	text-transform: uppercase;
	text-decoration: none;
	transition: all 0.3s ease-in-out;
}
.apod .actions .go-to{
	color: var(--main-nav);
	border-color: var(--main-nav);
	margin-right: 5px;
}
.apod .actions .go-to:hover,
.apod .actions .go-to:focus{
	text-decoration: underline;
}
.apod .actions .download{
	background-color: var(--main-nav);
	color: var(--plain-white);
}
.apod .actions .download:hover, 
.apod .actions .download:focus{
	text-decoration: underline;	
}

.apod:nth-child(even) .image-wrapper{
	order: 2;
}
.apod:nth-child(even) .apod-info{
	order: 1;
}
.apod:nth-child(even) .actions{
	right: auto;
	left: 0;
}
/* Dark Mode */
body.nightmode .apod{
	background-color: var(--apod-bg-dark);	
}
body.nightmode .apod .apod-info h2 a{
	color: var(--plain-white);
}
body.nightmode .apod .apod-info p{
	color: var(--text-color-dark);
}
body.nightmode .apod .apod-info .meta label{
	color: var(--blue-color-dark);		
}
body.nightmode .apod .actions .go-to{
	color: var(--blue-color-dark);
	border-color: var(--blue-color-dark);
}

body.nightmode .apod .actions .download{
	background-color: var(--blue-color-dark);
}

@media only screen and (max-width: 1270px) {
	.apod{
		width: 100%;
		height: auto;
		flex-direction: column;
		padding-bottom: 70px;
	}
	.apod .image-wrapper, 
	.apod .apod-info{
		width: 100%;
	}
	.apod:nth-child(even) .image-wrapper{
		order: 1;
	}
	.apod:nth-child(even) .apod-info{
		order: 2;
	}
	.apod:nth-child(even) .actions{
		right: 0;
		left: auto;
	}
	.apod .apod-info .meta{
		flex-wrap: wrap;
	}
}