.main-header{
	background-color: var(--main-nav);
	color: var(--plain-white);
	margin-bottom: 100px;	
	padding: 10px 15px;
	border-bottom: 4px solid var(--border-color);
	transition: all 0.2s ease-in-out;
}
.main-header .container{
	display: flex;	
}
.main-header .container .logo-wrapper h1{
	margin: 0px;
	height: 90px;
}
.main-header .container .logo-wrapper img{
	width: auto;
	height: 90px;
}
.main-header .container .nav-wrapper{
	flex-grow: 1;
	text-align: right;
}
.main-header .container .nav-wrapper .menu-toggler{
	display: none;
	font-size: 18px;
	font-weight: bold;
	text-transform: uppercase;
}
.main-header .container .nav-wrapper ul{
	margin: 0px;
	padding: 0px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	height: 100%;
}
.main-header .container .nav-wrapper ul li{
	list-style: none;
}
.main-header .container .nav-wrapper ul li a{
	font-size: 18px;
	font-weight: 600;
	padding: 10px 15px;
	color: var(--plain-white);
	text-decoration: none;
	text-transform: uppercase;
}
.main-header .container .nav-wrapper ul li.mode-toogle div{
	display: inline-block;
	width: 30px;
	height: 15px;
	background-color: var(--plain-white);
	border-radius: 25px;
	overflow: hidden;
	padding: 0px;
	transition: all 0.3s ease-in-out;
	position: relative;
}
.main-header .container .nav-wrapper ul li.mode-toogle div span{
	width: 15px;
	height: 15px;
	display: inline-block;
	border: 1px solid #fff;
	border-radius: 50%;
	background-color: var(--text-color);
	position: absolute;
	top: 0;
	left: 0px;
	box-sizing: border-box;
	transition: all 0.3s ease-in-out;
}
.main-header .container .nav-wrapper ul li.mode-toogle div.active{
	background-color: var(--text-color);
}
.main-header .container .nav-wrapper ul li.mode-toogle div.active span{
	background-color: var(--plain-white);
	border-color: var(--text-color);
	left:15px;	
}

/*Main footer*/
.main-footer{
	background: rgb(0,24,65);
	background: -moz-linear-gradient(0deg, rgba(0,24,65,1) 0%, rgba(11,61,145,1) 100%);
	background: -webkit-linear-gradient(0deg, rgba(0,24,65,1) 0%, rgba(11,61,145,1) 100%);
	background: linear-gradient(0deg, rgba(0,24,65,1) 0%, rgba(11,61,145,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#001841",endColorstr="#0b3d91",GradientType=1);
	margin-top: 100px;
	height: 200px;
	padding-top: 30px;
	padding-bottom: 20px;
	color: var(--plain-white);
	transition: all 0.2s ease-in-out;
}

.main-footer .container{
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}

.main-footer .container .footer-item{
	width: 27%;
	padding-left: 30px;
	padding-right: 30px;
	box-sizing: border-box;
}
.main-footer .container .footer-item h2{
	margin-top: 0px;
	margin-bottom: 20px;
	font-weight: 600;
	font-size: 18px;
}
.main-footer .container .footer-item ul{
	margin: 0px;
	padding: 0px;
}
.main-footer .container .footer-item ul li{
	list-style: none;
	margin-bottom: 10px;
}
.main-footer .container .footer-item ul li a{
	font-size: 14px;
	font-weight: 400;
	text-decoration: none;
	transition: all 0.3s ease-in-out;
	color: var(--plain-white);
}
.main-footer .container .footer-item ul li a:hover,
.main-footer .container .footer-item ul li a:focus{
	text-decoration: underline;
}
.main-footer .container .footer-item ul li a:visited{
	color: var(--plain-white);
}
.main-footer .container .footer-item.logo-wrapper{
	padding-top: 30px;
}
.main-footer .container .footer-item.logo-wrapper img{
	width: 100%;
}
.main-footer .container .copyright{
	width: 100%;
	text-align: center;
	font-size: 12px;
	margin-top: 15px;
}
.main-footer .container .copyright a{
	color: var(--nasa-worm-color);
	text-decoration: none;
	font-weight: 600;
}
/*Dark Mode*/
body.nightmode .main-header{
	background-color: var(--main-nav-dark);
}
body.nightmode .main-footer{
	background: rgb(24,25,26);
	background: linear-gradient(0deg, var(--bg-color-dark) 0%, var(--apod-bg-dark) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#001841",endColorstr="#0b3d91",GradientType=1);
}


@media only screen and (max-width: 740px) {
	.main-header .container{
		flex-direction: column;
	}
	.main-header .logo-wrapper{
		text-align: center;
		margin-bottom: 30px;
	}
	.main-header .container .logo-wrapper img{
		height: 110px;
	}
	.main-header .container .nav-wrapper .menu-toggler{
		display: block;
		cursor: pointer;
	}
	.main-header .container .nav-wrapper ul{
		flex-wrap: wrap;
		align-items: start;
		flex-direction: column;
		overflow: hidden;
		height: 0;
		transition: all 0.1s ease-in-out;
	}
	.main-header .container .nav-wrapper ul li{
		opacity: 0;
		transition: all 0.4s ease-in-out;
	}	
	.main-header .container .nav-wrapper ul li{
		margin-bottom: 10px;
		width: 100%;
		text-align: left;
	}
	.main-header .container .nav-wrapper ul li a{
		width: 100%;
	}
	.main-header .container .nav-wrapper ul.open{
		height: 145px;
	}
	.main-header .container .nav-wrapper ul.open li{
		opacity: 1;
	}
	.main-header .container .nav-wrapper ul li.mode-toogle{
		text-align: right;
	}
	.main-footer {
		height: auto;
	}
	.main-footer .container{
		flex-direction: column;
	}
	.main-footer .container .footer-item{
		width: 100%;
		padding-left: 0px;
		padding-right: 0px;
		margin-bottom: 20px;
	}

}