@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,600;1,300&display=swap');
:root {

	/* cores */
	--main-nav: #0B3D91;
	--main-nav-dark: #242526;
	--header-color: #000000;
	--text-color: #54595F;
	--text-color-dark: #B9B9B9;
	--plain-white: #ffffff;
	--apod-bg-dark: #242526;
	--bg-color: #F9F9F9;
	--bg-color-dark: #18191A;
	--nasa-worm-color: #DB362D;
	--border-color: #3357E6;
	--blue-color-dark: #0057FF;
	--gradient-1: #0B3D91;
	--gradient-2: #001841;
}
body{
	margin: 0px;
	padding: 0px;	
	background-color: var(--bg-color);
	font-family: 'Poppins', sans-serif;
	transition: background-color 0.2s ease-in;
}
.container {
  margin: 0px auto;
  max-width: 1320px;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
}
.loading{
	min-height: 60vh;
	width: 100%;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
}
.other-entries{
	margin: 100px 0px;
}
.other-entries h2{
	margin: 0px;
	font-size: 70px;
	font-weight: 600;
	color: var(--main-nav);
	position: relative;
	display: inline-block;
}
.other-entries h2:after{
	content: "";
	background-color: var(--main-nav);
	width: 110%;
	height: 4px;
	left: 0;
	bottom: 0;
	position: absolute;
}

/* Dark Mode */
body.nightmode{
	background-color: var(--bg-color-dark);
}
@media only screen and (max-width: 740px) {
	.other-entries h2{
		text-align: center;	
		display: block;
		font-size: 34px;
	}
	.other-entries h2:after{
		width: 100%;
	}
}