.main-content{
	min-height: 60vh;
	padding-left: 15px;
	padding-right: 15px;
	background-color: var(--plain-white);
	box-shadow: 0px 3px 6px #00000029;
}
.about-page{
	padding-top: 20px;	
}
.about-page h1{
	margin-top: 0px;
	margin-bottom: 30px;
}
.about-page p{
	font-size: 16px;
	line-height: 27px;
	font-weight: lighter;
}
.about-page p a{
	font-weight: bold;
	color: var(--main-nav);	
}

/* apod Page*/
.apod-page{
	padding: 50px 15px;
	transition: all 0.2s ease-in-out;
}
.apod-page figure{
	margin: 0px auto 50px;
	text-align: center;
	width: 1020px;
}
.apod-page figure img{
	max-width: 100%;
	width: 100%;	
}
.apod-page figure figcaption{
	text-align: right;
	color: var(--text-color);
	font-style: italic;
	font-size: 12px;
}
.apod-page .iframe-wrapper{
	margin: 0px auto 50px;
	text-align: center;
	width: 1020px;
}
.apod-page .iframe-wrapper p{
	margin: 0px; 
	text-align: right;
	color: var(--text-color);
	font-style: italic;
	font-size: 12px;
	max-width: 960px;
}
.apod-page .apod-info{
	max-width: 900px;
	margin: 0px auto;	
}

.apod-page .apod-info h1{
	font-size: 35px;
	font-weight: 600;
	margin-top: 0px;
	margin-bottom: 20px;	
	color: var(--header-color);
}
.apod-page .apod-info p.explanation{
	margin-top: 0px;
	margin-bottom: 30px;
	font-size: 16px;
	line-height: 27px;
	font-weight: 400;	
	color: var(--text-color);
}
.apod-page .apod-info .meta{
	display: flex;
	font-size: 16px;
	font-weight: 300;
	color: var(--text-color);
	font-style: italic;
	margin-bottom: 50px;	
}
.apod-page .apod-info .meta p{
	margin-right: 30px;
}
.apod-page .apod-info .meta label{
	font-weight: 600;
	font-style: normal;
	color: var(--main-nav);
	margin-right: 5px;
}
.apod-page .apod-info .actions{
	text-align: right;
}
.apod-page .apod-info .actions a{
	display: inline-block;
	padding: 15px 25px;
	font-weight: 600;
	font-size: 16;
	border: 2px solid transparent;
	text-transform: uppercase;
	text-decoration: none;
	transition: all 0.3s ease-in-out;
}
.apod-page .apod-info .actions .go-to{
	color: var(--main-nav);
	border-color: var(--main-nav);
	margin-right: 5px;
}
.apod-page .apod-info .actions .go-to:hover,
.apod-page .apod-info .actions .go-to:focus{
	text-decoration: underline;
}
.apod-page .apod-info .actions .download{
	background-color: var(--main-nav);
	color: var(--plain-white);
}
.apod-page .apod-info .actions .download:hover, 
.apod-page .apod-info .actions .download:focus{
	text-decoration: underline;	
}

/* Dark Mode*/
body.nightmode .apod-page {
	background-color: var(--apod-bg-dark);
}
body.nightmode .apod-page .apod-info h1{
	color: var(--plain-white);
}
body.nightmode .apod-page figure figcaption,
body.nightmode .apod-page .apod-info p.explanation{
	color: var(--text-color-dark);
}
body.nightmode .apod-page .apod-info .meta{
	color: var(--text-color-dark);
}
body.nightmode .apod-page .apod-info .meta label{
	color: var(--blue-color-dark);
}
body.nightmode .apod-page .apod-info .actions .go-to{
	color: var(--blue-color-dark);
	border-color: var(--blue-color-dark);
}
body.nightmode .apod-page .apod-info .actions .download{
	background-color: var(--blue-color-dark);
}

@media only screen and (max-width: 740px) {
	.apod-page .iframe-wrapper{
		width: 100%;
		height: 430px;
	}
	.apod-page .iframe-wrapper iframe{
		width: 100%;
		height: 100%;
	}
	.apod-page figure{
		width: 100%;
	}
}
@media only screen and (max-width: 400px) {
	.apod-page .iframe-wrapper{
		width: 100%;
		height: 190px;
	}
	.apod-page .iframe-wrapper iframe{
		width: 100%;
		height: 100%;
	}
}